.logo-bar {
  @include section;
  text-align: center;

  .wrap { @include wrap; }

  .block-title { margin-bottom: ($gutter-base*2); }

  .nav {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-height: 4.375rem;
    margin: ($gutter-base/2) 0;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    @media (min-width: 750px) { margin: ($gutter-base) 0; }

    .el {
      text-align: center;
      flex: 0 0 33.3333%;
      @include fadeOut(.6s);

      @media (min-width: 750px) { flex-basis: 25%; }
      @media (min-width: 1000px) { flex-basis: 20%; }

      &.state-animate {
        @include fadeInDownLight(.6s);
        @include quick-delayed-elements;
      }
    }
  }

  .brand {
    display: block;
    text-align: center;
    padding: 0 ($gutter-base/6);
    filter: grayscale(100%);
    transition: all .3s ease-out;

    @media (min-width: 400px) { padding: 0 ($gutter-base/3); }

    img {
      display: inline-block;
      max-width: 100%;
      max-height: 4.375rem;
      opacity: .5;
      transition: opacity .3s ease-out;
    }
  }
    a.brand:hover {
      filter: grayscale(0);
      img {opacity: 1;}
    }
}