.video-block {
  @media (max-width: 999px) {
    padding-top: ($gutter-base*3);
    padding-bottom: ($gutter-base*3);
  }

  @media (min-width: 1000px) {
    padding-top: ($gutter-base * 5);
    padding-bottom: ($gutter-base * 5);
  }

  .wrap {
    @include wrap;
    position: relative;
  }

  .hexagon {
    position: absolute;
    top: -8.125rem; left: -13.75rem;
    display: block;
    width: 100%;
    max-width: 26.5625rem;
    transform: rotate(-25deg);
    z-index: -1;

    @media (min-width: 1000px) {
      top: -10rem;
      left: -4.375rem;
    }
  }

  .inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }

  iframe {
    width: 100%;
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 1.5625rem;
  }
}