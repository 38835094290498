*,
*::before,
*::after { box-sizing: border-box; }

html,
body {
  margin: 0;
  padding: 0;
}

html {
  text-size-adjust:         100%;
  -ms-text-size-adjust:     100%;
  -moz-text-size-adjust:    100%;
  -webkit-text-size-adjust: 100%;
}

picture,
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section { display: block; }

img,
iframe {
  vertical-align: middle;
  border-style: none;
}

form { margin: 0 auto; }

input,
textarea,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 0;
  margin: 0;
}

select::-ms-expand { display: none; }
input[type='search']::-webkit-search-decoration { -webkit-appearance: none; }

input[type='radio'],
input[type='checkbox'] { padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] { -webkit-appearance: button; }