.usp-block {
  @include section;
  @include wrap;

  .wrap {

    @media (min-width: 1000px) {
      display: flex;
      margin-left: (-($gutter-base/2));
      margin-right: (-($gutter-base/2));
    }
  }

  .block {
    @media (min-width: 1000px) { flex: 0 0 33.3333%; }

    @media (max-width: 1000px) { &:not(:last-child) { margin-bottom: ($gutter-base); } }

    .inner {
      @media (min-width: 1000px) {
        padding: 0 ($gutter-base/2);
      }
    }

    .heading-box {
      display: flex;
      align-items: center;
      margin-bottom: ($gutter-base/3);

      img {
        flex: 0 0 auto;
        display: block;
      }

      .title {
        font-family: $font-family-heading;
        font-weight: $font-weight-bold;
        margin-bottom: 0;
        flex: 1 1 auto;
        padding-left: ($gutter-base/3);
        line-height: 1.2;
      }
    }

    .content { margin-bottom: 0; }
  }
}