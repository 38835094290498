@mixin animate($name, $duration) {
  animation: $name $duration;
  animation-fill-mode: both;
}

@mixin fadeOut($duration) {
  @include animate(fadeOut, $duration);
}
  @keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
  }

@mixin fadeIn($duration) {
  @include animate(fadeIn, $duration);
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@mixin fadeInDown($duration) {
  @include animate(fadeInDown, $duration);
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@mixin fadeInDownLight($duration) {
  @include animate(fadeInDownLight, $duration);
}
@keyframes fadeInDownLight {
  from {
    opacity: 0;
    transform: translate3d(0, -15%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@mixin spin($duration) {
  @include animate(spin, $duration);
  animation-iteration-count: infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@mixin quick-delayed-elements {
  &:nth-child(18n+1) {animation-delay: .1s;}
  &:nth-child(18n+2) {animation-delay: .05s;}
  &:nth-child(18n+3) {animation-delay: .15s;}
  &:nth-child(18n+4) {animation-delay: .25s;}
  &:nth-child(18n+5) {animation-delay: .2s;}
  &:nth-child(18n+6) {animation-delay: .3s;}
  &:nth-child(18n+7) {animation-delay: .4s;}
  &:nth-child(18n+8) {animation-delay: .35s;}
  &:nth-child(18n+9) {animation-delay: .45s;}
  &:nth-child(18n+10) {animation-delay: .55s;}
  &:nth-child(18n+11) {animation-delay: .5s;}
  &:nth-child(18n+12) {animation-delay: .6s;}
  &:nth-child(18n+13) {animation-delay: .7s;}
  &:nth-child(18n+14) {animation-delay: .65s;}
  &:nth-child(18n+15) {animation-delay: .75s;}
  &:nth-child(18n+16) {animation-delay: .85s;}
  &:nth-child(18n+17) {animation-delay: .8s;}
  &:nth-child(18n+18) {animation-delay: .9s;}
}