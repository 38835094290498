.experiences-block {
  @include section;

  .wrap { @include wrap; }
  .content {
    @media (min-width: 1000px) {
      float: left;
      width: ($gutter-base*10);
      padding-right: ($gutter-base);
    }
  }

  .slider-wrap {
    position: relative;
    
    .hexagon {
      position: absolute;
      top: 5rem ;left: -3.125rem;
      z-index: -1;
      max-width: 13.75rem;
      width: 100%;
      display: block;
      transform: rotate(-25deg);

      @media (min-width: 750px) {
        top: ($gutter-base); left: 17.5rem;
        max-width: 18.75rem;
      }

      @media (min-width: 1000px) {
        top: (-($gutter-base*3));
        max-width: 21.875rem;
      }
    }
  }

  .slider {

    .slick-list {
      @media (max-width: 750px) { margin-right: (-($gutter-base)); }
    }

    .el {
      background-color: $background-color-tertiary;
      border-radius: 1.25rem;
      padding: ($gutter-base);
      margin-right: ($gutter-base);

      @media (min-width: 750px) { max-width: 33.75rem; }

      .text {
        position: relative;
        max-width: 80%;
        margin: 0 auto;

        &:before, &:after {
          content: '"';
          display: block;
          position: absolute;
          font-family: $font-family-heading;
          font-weight: $font-weight-bold;
          font-size: rem-calc(60);
          height: 1.4375rem;
          line-height: 1;
        }

        &:before {
          left: (-($gutter-base)); top: 0;
        }

        &:after {
          right: (-($gutter-base)); bottom: 0;
        }
      }

      .author {
        font-family: $font-family-heading;
        font-weight: $font-weight-bold;
        font-style: italic;
        max-width: 80%;
        margin: 0 auto;
        margin-top: ($gutter-base/2);
      }
    }
  }
}