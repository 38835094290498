.files-block {
  // @include section;
  overflow: hidden;

  @media (max-width: 999px) {
    padding-top: ($gutter-base*3);
    padding-bottom: ($gutter-base*3);
  }

  @media (min-width: 1000px) {
    padding-top: ($gutter-base * 4);
    padding-bottom: ($gutter-base * 4);
  }

  .wrap {
    @include wrap;
    position: relative;
    // margin-top: (-($gutter-base));

    .hexagon {
      position: absolute;
      max-width: 12.5rem;
      z-index: -1;
      bottom: (-($gutter-base*2)); right: 0;
      transform: rotate(-20deg);

      @media (min-width: 750px) {
        right: 13.75rem;
        max-width: 18.75rem;
      }
    }
  }

  .block-title { margin-bottom: ($gutter-base/2); }

  .files {
    @media (min-width: 750px) {
      display: flex;
      flex-wrap: wrap;
      margin-left: (-($gutter-base/2));
      margin-right: (-($gutter-base/2));
    }

    &:nth-child(odd) .el {
      @media (max-width: 750px) {
        &:nth-child(1n) .inner { background-color: #F0D9F1; }
        &:nth-child(2n) .inner { background-color: #EAF0F6; }
        &:nth-child(3n) .inner { background-color: #F8CCBC }
        &:nth-child(4n) .inner { background-color: #B9E4CE; }
        &:nth-child(5n) .inner { background-color: #FBF2C2; }
        &:nth-child(6n) .inner { background-color: #F8CCBC }
      }
    }

    .el {
      margin-top: ($gutter-base);
      color: $text-color-base;
      display: flex;
      justify-content: center;
      align-items: center;

      
      &:nth-child(1n) .inner { background-color: #F8CCBC }
      &:nth-child(2n) .inner { background-color: #EAF0F6; }
      &:nth-child(3n) .inner { background-color: #B9E4CE; }
      &:nth-child(4n) .inner { background-color: #F0D9F1; }
      &:nth-child(5n) .inner { background-color: #FBF2C2; }
      &:nth-child(6n) .inner { background-color: #F8CCBC }

      @media (min-width: 750px) { flex: 0 0 50%; }
      @media (min-width: 1000px) { flex: 0 0 33.3333%; }
      @media (min-width: 1050px) {
        &:hover .inner { border-color: $text-color-base; }
      }
      

      .inner {
        flex: 0 1 100%;
        border-radius: 2.1875rem;
        min-height: 7.5rem;
        padding: ($gutter-base/2);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 750px) {
          margin: 0 ($gutter-base/2);
          min-height: 9.375rem;
        }

        @media (min-width: 1050px) {
          transition: border-color .2s ease-in;
          border: ($border-width-base) solid transparent;
        }
      }
    }

    .title {
      flex: 0 0 100%;
      margin-bottom: 0;
      font-family: $font-family-heading;
      font-weight: $font-weight-bold;
      text-align: center;
      font-size: rem-calc(21);
      line-height: 1.2;

      @media (min-width: 750px) {
        font-size: rem-calc(28);
      }
    }
  }
}