.text-block {
  @include section;

  .wrap {
    @include wrap;
    position: relative;
    
    @media (min-width: 1000px) { max-width: $max-width-small; }
  }

  .title {
    margin-bottom: ($gutter-base/2);
  }

  .text {
    margin-bottom: ($gutter-base/2);

    &:last-child { margin-bottom: 0; }
  }

  // shorter text block with hexagon 
  &.state-hexagon {
    overflow: hidden;
    @media (min-width: 1000px) { overflow: visible; }

    .content {
      @media (min-width: 1000px) { max-width: 34.6875rem; }
    }

    .hexagon {
      display: block;
      width: 100%;
      transform: rotate(-20deg);
      position: absolute;
      top: 1.25rem; right: 1.25rem;
      max-width: 6.25rem;
    
      @media (min-width: 400px) {
        max-width: 9.375rem;
        top: 0; 
      }
  
      @media (min-width: 1000px) {
        max-width: 21.25rem;
        top: (-$gutter-base);
        right: (-$gutter-base);
      }
    }
  }
}