.intro-banner {
  @media (max-width: 999px) {
    padding-top: ($gutter-base*4);
    padding-bottom: ($gutter-base)*2;
  }

  @media (min-width: 1000px) {
    padding-top: ($gutter-base * 6);
    padding-bottom: ($gutter-base * 3);
  }

  .wrap { @include wrap; }

  .title {
      font-size: rem-calc(28);
      margin-bottom: ($gutter-base/2);
      line-height: 1.2;

      @media (min-width: 750px) { font-size: rem-calc(45); }
      @media (min-width: 1000px) { font-size: rem-calc(60); }
    }

    .text { margin-bottom: 0; }
    .desc { margin-bottom: ($gutter-base/2); }

  .scroll-to {
    display: block;
    text-align: center;
    margin-top: ($gutter-base);

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: ($gutter-base);
      width: ($gutter-base);
      border-radius: 25rem;
      background-color: $background-color-secondary;

      img {
        max-width: 0.875rem;
        width: 100%;
        flex: 0 0 0.875rem;
      }
    }
  }

  &.state-home {

    .wrap {
      @media (min-width: 750px) {
        display: flex;
        align-items: center;
      }
    }

    .image {
      position: relative;
      margin-top: ($gutter-base);

      @media (min-width: 750px) {
        flex: 0 0 15rem;
        order: 1;
      }

      @media (min-width: 1000px) { flex-basis: 25.625rem; }

      .bottom {
        width: 100%;
        display: block;
        max-width: 25.625rem;
        margin: 0 auto;
      }

      .top {
        width: 102%;
        display: block;
        max-width: 26.25rem;
        position: absolute;
        top: 0; left: 50%;
        transform: translateX(-50%);
        z-index: $zindex-scale*2;

        @media (min-width: 750px) {
          left: 0;
          transform: none;
        }
      }
    }

    .content {
      @media (min-width: 750px) {
        flex: 1 1 auto;
        margin-left: auto;
        order: 2;
        padding-left: ($gutter-base);
      }

      @media (min-width: 1000px) { flex: 0 1 38.75rem; }
    }
  }

  &.state-primary {
    .wrap { position: relative; }

    .content {
      @media (min-width: 750px) {
        max-width: 80%;
        margin-left: auto;
      }
    }

    .text-wrap {
      @media (min-width: 750px) {
        padding-left: ($gutter-base*2);
      }
    }

    .image {
      position: absolute;
      right: 0.75rem; top: -0.625rem;
      opacity: .4;

      @media (min-width: 750px) {
        top: -3.125rem; left: 0; right: auto;
        opacity: 1;
      }

      img {
        max-width: 12.5rem;
        display: block;
        width: 100%;

        @media (min-width: 550px) { max-width: 18.75rem; }
        @media (min-width: 750px) { max-width: 23.4375rem; }
      }
    }
  }
}