// Automated rem sizes
@function rem-calc($size) {
  @return ($size / 16) * 1rem;
}

// Font settings
$font-family-base:    sans-serif;
$font-family-heading: 'Roboto', sans-serif;
//$font-family-base:    'Font Face Family Name', sans-serif;

$font-size-small:     rem-calc(14); 
$font-size-base:      rem-calc(16); 
$font-size-medium:    rem-calc(18); 
$font-size-large:     rem-calc(21); 

$heading-size-tiny:   rem-calc(24); 
$heading-size-small:  rem-calc(32); 

$line-height-base:    1.5;
$line-height-small:   1.25;

// $font-weight-light:   100;
$font-weight-normal:  300;
$font-weight-bold:    700;
$font-weight-base:    $font-weight-normal;

// Size settings
$max-width-base:      rem-calc(1150); //or a value in vw if you use the fluid-font
$max-width-small:     rem-calc(920); //or a value in vw if you use the fluid-font
$gutter-base:         rem-calc(30);

$border-radius-base:  rem-calc(4);
$border-width-base:   rem-calc(1);

// Z-index settings
$zindex-menu:         1337;
$zindex-scale:        10;
$zindex-modal-bg:     (($zindex-menu) + ($zindex-scale));
$zindex-modal:        (($zindex-modal-bg) + ($zindex-scale));

// Color settings
$text-color-base:       #000;
$text-color-medium:     transparentize($text-color-base, .35);
$text-color-light:      transparentize($text-color-base, .5);
$text-color-white:      #FFFFFF;

$background-color-base:         #FFF;
$background-color-secondary:    #E2EBF4;
$background-color-tertiary:     #F6F9FB;
$background-color-placeholder:  transparentize($text-color-base, .9);

$border-color-base:     #E9E9E9;

$primary-color:         #E95523;
$primary-hover-color:   darken($primary-color, 20%);

$positive-color:        #17A65D;
$negative-color:        #E68B80;

// LazyLoad settings
@mixin aspect-ratio-container($width, $height) {
  padding-bottom: (($height / $width) * 100%);
}

%aspect-ratio-image {
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}