.footer {
  background-color: $background-color-tertiary;
  border-top: ($border-width-base) solid $text-color-base;
  text-align: center;

  @media (max-width: 999px) {
    padding-top: ($gutter-base*2);
    padding-bottom: ($gutter-base*2);
  }

  @media (min-width: 1000px) {
    padding-top: ($gutter-base * 2);
    padding-bottom: ($gutter-base * 2);
  }

  .wrap { @include wrap; }

  .inner {
    @media (min-width: 750px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .logo {
    @media (min-width: 750px) { flex: 0 0 100%; }
    @media (min-width: 1000px) {
      flex: 0 0 25%;
      margin-right: auto;
    }

    img {
      width: 100%;
      display: block;
      max-width: 16.875rem;
      margin: 0 auto;
    }
  }

  .row,
  .info {
    margin-top: ($gutter-base);
    padding: 0 ($gutter-base/3);

    @media (min-width: 750px) { flex: 0 0 33.33%; }
    @media (min-width: 1000px) { flex: 0 0 20%; }

    p {
      margin-bottom: 0.3125rem;
      line-height: 1.1;
      font-size: $font-size-small;
    }

    a {
      display: block;
      padding: 0 0.3125rem;
    }

    .label {
      font-weight: $font-weight-bold;
      font-family: $font-family-heading;
      margin-bottom: ($gutter-base/3);
    }   
  }

  .info p { font-size: rem-calc(12); }
}