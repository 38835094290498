// @font-face {
//   font-family: 'Flaticon';
//   src: url('/templates/fonts/Flaticon.eot');
//   src: url('/templates/fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
//   url('/templates/fonts/Flaticon.woff') format('woff'),
//   url('/templates/fonts/Flaticon.ttf') format('truetype'),
//   url('/templates/fonts/Flaticon.svg#Flaticon') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @media screen and (-webkit-min-device-pixel-ratio: 0) {
//   @font-face {
//     font-family: 'Flaticon';
//     src: url('/templates/fonts/Flaticon.svg#Flaticon') format('svg');
//   }
// }

@mixin icon-font {
  //font-family: 'Flaticon';
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before,
[class^='icon-']:after,
[class*=' icon-']:after {
  @include icon-font;
}

.icon-name:before { content: ''; }
.icon-name:after { content: ''; }
