.image-slider-block {
  @include section;

  .wrap { @include wrap; }

  .slick-list {
    margin-right: (-($gutter-base));
  }

  .el {
    border-radius: ($border-radius-base*2);
    overflow: hidden;
    margin-right: ($gutter-base);
  }
  
  img {
    display: block;
    width: 100%;
    border-radius: ($border-radius-base*2);
  }
}