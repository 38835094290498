.contact-block {
  background-color: $background-color-secondary;
  border-top: ($border-width-base) solid $text-color-base;
  border-bottom: ($border-width-base) solid $text-color-base;

  & + .footer { border-top: none; }
  
  @media (max-width: 999px) {
    padding-top: ($gutter-base*2);
    padding-bottom: ($gutter-base*2);
  }

  @media (min-width: 1000px) {
    padding-top: ($gutter-base * 4);
    padding-bottom: ($gutter-base * 4);
  }

  .wrap {
    @include wrap;  
    max-width: $max-width-small;
  }

  .contact {
    @media (min-width: 1000px) {
      display: flex;
      // align-items: center;
      margin-left: (-($gutter-base));
      margin-right: (-($gutter-base));
    }
  }

  .content {
    @media (min-width: 1000px) { flex: 0 0 50%; }
    @media (max-width: 1000px) { margin-bottom: ($gutter-base*1.5); }

    .text { margin-bottom: 0; }
  }

  .inner {
    @media (min-width: 1000px) { padding: 0 ($gutter-base); }
  }

  .title {
    font-size: $heading-size-tiny;
    margin-bottom: ($gutter-base/2);
    line-height: 1.2;

    @media (min-width: 600px) { font-size: $heading-size-small; }
  }

  .button-wrap {
    margin-top: ($gutter-base);
    text-align: right;
  }
}

.contact-form {
  @media (min-width: 1000px) {
    flex: 0 0 50%;
  }

  input,
  textarea {
    border: none;
    background: transparent;
    padding: ($gutter-base/3);
    padding-left: 0;
    border-bottom: ($border-width-base) solid $text-color-base;

    &::placeholder {
      font-family: $font-family-heading;
      font-weight: $font-weight-bold;
      color: $text-color-base;
    }

    &.state-invalid {
      border-bottom-color: $negative-color;
    }
  }

  textarea {
    transition: height .1s ease-in;
    // height: ($gutter-base*1.5);
    height: 9.375rem;

    // &:focus { height: ($gutter-base*3); }
  }

  .form-row {
    position: relative;

    & + .form-row { margin-top: ($gutter-base/2); }

    .check {
      position: absolute;
      top: 1.0625rem; right: 0;
      line-height: 1;
      font-weight: $font-weight-bold;
      font-family: $font-family-heading;
    }
  }
}