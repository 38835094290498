.results-block {
  @include section;
  background-color: $positive-color;
  margin-top: ($gutter-base*2);

  @media (min-width: 750px) {
    margin-top: ($gutter-base*4);
  }

  .wrap {
    @include wrap;
    max-width: $max-width-small;
  }

  .outer {
    display: flex;
    flex-wrap: wrap;
    margin-top: (-($gutter-base));
    margin-left: (-($gutter-base/3));
    margin-right: (-($gutter-base/3));

    @media (min-width: 750px) { margin-top: (-($gutter-base*1.5)); }
  }

  .el {
    text-align: center;
    flex: 0 0 50%;
    margin-top: ($gutter-base);

    @media (min-width: 750px) {
      flex-basis: 33.3333%;
      margin-top: ($gutter-base*1.5);
    }

    .inner {
      padding: 0 ($gutter-base/3);
    }

    img {
      max-height: 2.5rem;
      margin-left: auto;
      margin-right: auto;
      display: block;
      margin-bottom: ($gutter-base/2);

      @media (min-width: 750px) {
        max-height: 5.625rem;
      }
    }

    .count {
      color: $text-color-white;
      font-size: rem-calc(32);
      font-family: $font-family-heading;
      font-weight: $font-weight-bold;
      color: $text-color-white;
      margin-bottom: ($gutter-base/2);
      line-height: 1;

      @media (min-width: 750px) {
        font-size: rem-calc(40);
      }
    }

    .text {
      margin-bottom: 0;
      color: $text-color-white;
      font-size: $font-size-small;
      line-height: 1.2;
    }
  }
}