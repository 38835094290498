// Include your @font-face family here if you need one (either a custom font or a Google Font @import)
// Note that you can import multiple Google Font font families with one @import, you don't need multiple lines for this

// @font-face {
//   font-family: 'Font Face Family Name';
//   src: url('/templates/fonts/Font-Normal.eot');
//   src: url('/templates/fonts/Font-Normal.eot?#iefix') format('embedded-opentype'),
//   url('/templates/fonts/Font-Normal.woff2') format('woff2'),
//   url('/templates/fonts/Font-Normal.woff') format('woff'),
//   url('/templates/fonts/Font-Normal.ttf') format('truetype'),
//   url('/templates/fonts/Font-Normal.svg#Font-Normal') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Font Face Family Name';
//   src: url('/templates/fonts/Font-Normal-Italic.eot');
//   src: url('/templates/fonts/Font-Normal-Italic.eot?#iefix') format('embedded-opentype'),
//   url('/templates/fonts/Font-Normal-Italic.woff2') format('woff2'),
//   url('/templates/fonts/Font-Normal-Italic.woff') format('woff'),
//   url('/templates/fonts/Font-Normal-Italic.ttf') format('truetype'),
//   url('/templates/fonts/Font-Normal-Italic.svg#Font-Normal-Italic') format('svg');
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

// Start // Roboto is used as a test case but remove this if you use a custom font
@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i');
// End // Roboto is used as a test case but remove this if you use a custom font
