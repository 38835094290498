html,
body {
  // @include fluid-font(1.111vw, 16px, 19.2px, 16px);
  font-family: $font-family-base;
  font-size: $font-size-base; //remove this if you use the fluid-font
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $text-color-base;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: $gutter-base;
  font-weight: $font-weight-bold;
  font-family: $font-family-heading;
}

h2 {
  font-size: $font-size-medium;

  @media (min-width: 750px) { font-size: $heading-size-tiny; }
}

p {
  margin-top: 0;
  margin-bottom: $gutter-base;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: $gutter-base;

  ol,
  ul { margin-bottom: 0; }
}

blockquote {
  font-style: italic;
  margin: 0 0 $gutter-base;
}

b,
strong { font-weight: $font-weight-bold; }

a {
  color: $primary-color;
  transition: color .3s ease-out;
  text-decoration: none;

  @media(min-width: 1050px) {
    &:hover { color: $primary-hover-color; }
  }
}


.desc {
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
}
