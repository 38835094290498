::selection { background: transparentize($primary-color, .8); }

body {
  background-color: $background-color-base;
  overflow: hidden;
  overflow-y: scroll;
}

@mixin section {
  // @extend .component-section;

  @media (max-width: 999px) {
    padding-top: ($gutter-base*2);
    padding-bottom: ($gutter-base*2);
  }

  @media (min-width: 1000px) {
    padding-top: ($gutter-base * 4);
    padding-bottom: ($gutter-base * 4);
  }
}

// .component-section + .component-section {
//   @media (max-width: 1000px) { padding-top: 0; }
// }
// .component-section:not(.state-sub) + .component-section:not(.state-sub) { padding-top: 0; }

@mixin wrap {
  max-width: $max-width-base;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 999px) {
    padding-left: ($gutter-base / 2);
    padding-right: ($gutter-base / 2);
  }

  @media (min-width: 1000px) {
    padding-left: ($gutter-base);
    padding-right: ($gutter-base);
  }

  // include the max-width and margins from 1250 if you use fluid-font max-width
  // @media (min-width: 1250px) {
  //   max-width: $max-width-base;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
}

// If you don't end up using the widget-offset just remove the mixin
@mixin widget-offset {
  &:not(:first-child) {
    @media (max-width: 999px) { margin-top: ($gutter-base); }

    @media (min-width: 1000px) { margin-top: ($gutter-base * 2); }
  }
}

// If you don't end up using this primary-section set-up just remove all of its code
.primary-section {
  .main-content {
    // Keep or remove this logic
    & > *:last-child { margin-bottom: 0; }

    // @media (min-width: 1000px) {
    //   // Floats or flex-box settings here

    //   &:only-child,
    //   &:last-child {
    //     // If back-end properly does NOT render the sidebar what do you want?
    //     //   100% wide?
    //     //   centered?
    //     // Define it here with either the &:only-child, &:last-child
    //     //   Dot NOT use both, really make a choice here and remove the other
    //   }
    // }

    // Keep or remove this logic (for a mobile offset)
    & + .sidebar:not(:empty) {
      @media (max-width: 999px) { margin-top: ($gutter-base); }
    }

    & > picture {
      // Include the reservation of the (retina) image dimensions, preventing intrinsic ratios the glitch
      @extend %aspect-ratio-image;
      background: $background-color-placeholder;
      
      @media (max-width: 400px) {
        @include aspect-ratio-container(720, 410);
      }
      @media (min-width: 401px) and (max-width: 749px) {
        @include aspect-ratio-container(1000, 560);
      }
      @media (min-width: 750px) and (max-width: 999px) {
        @include aspect-ratio-container(2000, 1130);
      }
      @media (min-width: 1000px) {
        @include aspect-ratio-container(1310, 736);
      }
      // End of aspect-ratio reservations
    }
  }

  .sidebar {
    // @media (min-width: 1000px) {
    //   Floats or flex-box settings here
    // }

    // Keep or remove this logic
    &:empty { display: none; }
  }
}

hr {
  height: 0;
  border-width: $border-width-base 0 0;
  border-style: solid;
  border-color: $border-color-base;
  margin-top: 0;
  margin-bottom: $gutter-base;
}

.flex-img img {
  width: 100%;
  display: block;
}

.custom-nav {
  margin-top: ($gutter-base/2);
  text-align: right;

  .custom-prev,
  .custom-next {
    background: none;
    border: none;
    padding: 0;

    @media (min-width: 1050px) {
      transition: all .2s ease-in;

      &:hover {
        cursor: pointer;
        opacity: .2;
      }
    }

    img {
      display: block;
      width: 100%;
      max-width: 1.875rem;
    }
  }

  .custom-prev {
    transform: rotate(180deg);
    margin-right: ($gutter-base/3);
  }

  .custom-next { margin-left: ($gutter-base/3); }
}