.text-image-block {
  @include section;
  @include wrap;

  &.state-reverse {
    @media (min-width: 1000px) {
      .content { order: 2; }
      .image { order: 1; }
    }
  }

  .wrap {
    @media (min-width: 1000px) {
      display: flex;
      margin-left: (-($gutter-base));
      margin-right: (-($gutter-base));
    }
  }

  .title { margin-bottom: ($gutter-base/2); }

  .text {
    margin-bottom: ($gutter-base/2);

    &:last-child { margin-bottom: 0; }
  }

  .content {
    & + .image {
      margin-top: ($gutter-base);

      @media (min-width: 1000px) { margin-top: 0; }
    }

    @media (min-width: 1000px) { flex: 1 0 50%; }

    .inner {
      @media (min-width: 1000px) { padding: 0 ($gutter-base); }
    }
  }

  .image {
    @media (min-width: 1000px) {flex: 1 0 50%; }

    picture {
      @media (min-width: 1000px) { padding: 0 ($gutter-base); }
    }

    img {
      width: 100%;
      display: block;
      border-radius: 1.5625rem;
    }
  }
}


.about-page {

  .intro-banner + .text-image-block .wrap {
    position: relative;

    &:before {
      content: url(../img/elements/blue.svg);
      display: block;
      position: absolute;
      z-index: -1;
      transform: scale(0.7) rotate(45deg);
      right: 0.625rem; bottom: -11.25rem;

      @media (min-width: 1000px) {
        bottom: 1.25rem; right: -3.125rem; 
      }
    }
  }
}