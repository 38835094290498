#top {
  position: fixed;
  width: 100%;
  top: 0; left: 0;
  z-index: $zindex-menu;
  background-color: transparent;
  transition: background-color .1s ease-in;

  .wrap { @include wrap; }

  .inner {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 750px) {
      justify-content: space-between;
      align-items: center;
    }
  }

  .trigger-menu {
    flex: 0 0 auto;
    margin-left: auto;
    border: none;
    background: none;
    padding: 0;

    &:before {
      content: "Menu";
      font-family: $font-family-heading;
      display: inline-block;
      font-weight: $font-weight-bold;
      font-size: $font-size-large;
    }

    @media (min-width: 750px) { display: none; }
  }

  .main-menu {
    flex: 0 0 100%;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    display: none;
    margin-top: ($gutter-base/3);
    padding-top: ($gutter-base/3);
    border-top: ($border-width-base) solid rgba(0,0,0, .1);

    @media (min-width: 750px) {
      flex: 0 0 auto;
      display: block;
      border-top: none;
      margin-top: 0;
      padding-top: 0;
    }

    @media (min-width: 1000px) {
      background: $background-color-tertiary;
      padding: 1.25rem;
      border-radius: 25rem;
    }

    .el {
      &:not(:last-of-type) {
        margin-bottom: ($gutter-base/3);
        @media (min-width: 750px) { margin-bottom: 0; }
      }

      &.state-active a { color: $primary-color; }

      @media (min-width: 750px) {
        display: inline-block;

        & + .el { margin-left: ($gutter-base/2); }
      }
      
    }

    a {
      display: block;
      color: $text-color-base;
      font-family: $font-family-heading;
      font-weight: $font-weight-bold;
      font-style: italic;

      @media (min-width: 1050px) {
        &:hover { color: $primary-color; }
      }
    }
  }

  .inner {
    background: $background-color-tertiary;
    padding: ($gutter-base/3);
    margin-top: ($gutter-base/3);
    border-radius: ($gutter-base/3);

    @media (min-width: 1000px) {
      background: none;
      padding: 0;
    }
  }

  .logo {
    flex: 0 0 ($gutter-base*4);
    display: inline-block;

    @media (min-width: 1000px) {
      background: $background-color-tertiary;
      padding: ($gutter-base/3);
      border-radius: ($gutter-base);
      flex-basis: 10.9375rem;
    }

    img {
      display: block;
      width: 100%;
      max-width: ($gutter-base*4);

      @media (min-width: 1000px) { max-width: 10.9375rem; }
    }
  }
}

body.state-menu-open {
  @media (max-width: 750px) {
    overflow: hidden;

    #top {
      background-color: rgba(0,0,0, .5);
      height: 100%;

      .main-menu { display: block; }

      .trigger-menu:before { content: 'Sluiten'; }
    }
  }
}