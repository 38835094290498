.btn {
  cursor: pointer;
  background: $primary-color;
  transition: color .3s ease-out, background-color .3s ease-out, border-color .3s ease-out;
  border-radius: 25rem;
  border: none;
  padding: ($gutter-base/3) ($gutter-base/2);
  color: $background-color-base;
  font-size: $font-size-small;
  text-align: center;
  font-family: $font-family-heading;
  font-weight: $font-weight-bold;
  min-width: 12.5rem;

  // &.state-primary {}
  // &.state-secondary {}

  @media(min-width: 1050px) {
    &:hover, &:focus {
      background-color: $primary-hover-color;
    }
  }
}

.btn-cont {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -($gutter-base / 4);
  margin-top: -($gutter-base / 4);

  .btn {
    flex: 1 1 auto;
    margin-left: ($gutter-base / 4);
    margin-top: ($gutter-base / 4);
  }
}